export const environment = {
    production: true,
    api_url: 'http://164.52.192.27:8000/',
    single: 'https://single-variant.dev1.gva.semgenome.com/',
    server: 'UI_SERVER_DEV_AZURE',
    usersFileContainerName: 'user-file-system',
    azureStorageUrl: 'https://devdiscardedvariant.blob.core.windows.net',
    AWSurl: 'https://usermanagement.dev1.gva.semgenome.com/',
    report_url: 'https://reports.dev1.gva.semgenome.com',
    user : 'https://usermanagement.dev1.gva.semgenome.com/',
    status_case_init: 'https://case-init.dev1.gva.semgenome.com/',  // Case INIT 
    file: 'https://upload.dev1.gva.semgenome.com/file/',
    hpo : 'https://search.dev1.gva.semgenome.com/',
    rules : 'https://rules.dev1.gva.semgenome.com/',
    info: 'https://input-info.dev1.gva.semgenome.com/',
    advanced_settings : 'https://advanced-settings.dev1.gva.semgenome.com/',  
    status_usergva: 'https://usergva.dev1.gva.semgenome.com/',
    filters: 'https://filters.dev1.gva.semgenome.com/',
    filter_panel: 'https://filter-panel.dev1.gva.semgenome.com/',
    cnv_acmg  : 'https://cnv-acmg.dev1.gva.semgenome.com/',
    cnv: 'https://cnv.dev1.gva.semgenome.com/',
    acmg : 'https://snv-acmg.dev1.gva.semgenome.com/',
    status_confirm : 'https://confirm.dev1.gva.semgenome.com/',
    viewset : 'https://kg-search.dev1.gva.semgenome.com/',  // KG Search URL
    pubmed : 'https://pubmed-integration.dev1.gva.semgenome.com/',
    vcf_settings : 'https://vcf-settings.dev1.gva.semgenome.com/',
    report : 'https://reports.dev1.gva.semgenome.com/',
    modify_usergva : 'https://case-init.dev1.gva.semgenome.com/',
    upload_file: 'https://notification.dev1.gva.semgenome.com/',
    report_image: 'https://reports.dev1.gva.semgenome.com/fetch', // Haeder and Footer
    key: 'IW84Zmx2a21wKW1hdWw2MzlrMSMxcGZk',
    env: 'azure',
    console: 'https://org-dashboard.dev1.gva.semgenome.com/',
    version: 'https://consoleapi.dev1.gva.semgenome.com/',
    oneDrive: 'b6b24f2f-d885-470a-bb05-77694a6b20b7',
    dropBox: '5cdmczlr2ozm9nj'
  };
  